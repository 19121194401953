// Used for overwriting ariaLiveMessages builtin onFocus method.
// Returns custom built string while focusing each group option. This string is used for screen reader announcement.
export function onFocus(onFocusProps, defaultOptions) {
  var _groupData$options$fi;
  var focused = onFocusProps.focused;
  var isOptionFocused = function isOptionFocused(option) {
    return option.label === focused.label;
  };
  var groupData = defaultOptions === null || defaultOptions === void 0 ? void 0 : defaultOptions.find(function (option) {
    var _option$options;
    return (_option$options = option.options) === null || _option$options === void 0 ? void 0 : _option$options.some(isOptionFocused);
  });
  var groupOptionIndex = (_groupData$options$fi = groupData === null || groupData === void 0 ? void 0 : groupData.options.findIndex(isOptionFocused)) !== null && _groupData$options$fi !== void 0 ? _groupData$options$fi : 0;
  return "Option ".concat(focused.label, ", ").concat(groupData === null || groupData === void 0 ? void 0 : groupData.label, " group, item ").concat(groupOptionIndex + 1, " out of ").concat(groupData === null || groupData === void 0 ? void 0 : groupData.options.length, ". All in all ");
}

// Helper function which identifies if options are grouped.
export var isOptionsGrouped = function isOptionsGrouped(arr) {
  return arr === null || arr === void 0 ? void 0 : arr.every(function (obj) {
    return obj.hasOwnProperty('options');
  });
};

// Helper function which calculates how many options are in total in all groups.
export var countAllOptions = function countAllOptions(groupsArray) {
  var totalLength = groupsArray === null || groupsArray === void 0 ? void 0 : groupsArray.reduce(function (acc, currentGroup) {
    var _group$options;
    var group = currentGroup;
    acc += group === null || group === void 0 || (_group$options = group.options) === null || _group$options === void 0 ? void 0 : _group$options.length;
    return acc;
  }, 0);
  return totalLength;
};