import _extends from "@babel/runtime/helpers/extends";
/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import { css, jsx } from '@emotion/react';

// Assistive text to describe visual elements. Hidden for sighted users.
var styles = css({
  width: 1,
  height: 1,
  padding: 0,
  position: 'absolute',
  zIndex: 9999,
  border: 0,
  clip: 'rect(1px, 1px, 1px, 1px)',
  label: 'a11yText',
  overflow: 'hidden',
  whiteSpace: 'nowrap'
});
var A11yText = function A11yText(props) {
  return jsx("span", _extends({
    css: styles
    // eslint-disable-next-line @repo/internal/react/no-unsafe-spread-props
  }, props));
};
export default A11yText;