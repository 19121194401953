import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { fg } from '@atlaskit/platform-feature-flags';
import { B100, B400, B50, G400, N0, N100, N20, N200, N30, N300, N40, N500, N70, N800, R400, R75 } from '@atlaskit/theme/colors';
export default function baseStyles(validationState) {
  var isCompact = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  var appearance = arguments.length > 2 ? arguments[2] : undefined;
  return {
    container: function container(css, _ref) {
      var isDisabled = _ref.isDisabled;
      return _objectSpread(_objectSpread({}, css), {}, {
        font: "var(--ds-font-body, normal 400 14px/20px ui-sans-serif, -apple-system, BlinkMacSystemFont, \"Segoe UI\", Ubuntu, system-ui, \"Helvetica Neue\", sans-serif)",
        // react-select disables pointer events when isDisabled is true.
        // We override this and make the inner container turn it off instead.
        pointerEvents: 'all',
        cursor: isDisabled ? 'not-allowed' : css.cursor
      });
    },
    input: function input(css) {
      return _objectSpread(_objectSpread({}, css), {}, {
        color: "var(--ds-text, hsl(0, 0%, 20%))"
      });
    },
    control: function control(css, _ref2) {
      var isFocused = _ref2.isFocused,
        isDisabled = _ref2.isDisabled;
      var borderColor = isFocused ? "var(--ds-border-focused, ".concat(B100, ")") : "var(--ds-border-input, ".concat(N100, ")");
      var backgroundColor = isFocused ? "var(--ds-background-input-pressed, ".concat(N0, ")") : "var(--ds-background-input, ".concat(N20, ")");
      var backgroundColorHover = isFocused ? "var(--ds-background-input-pressed, ".concat(N0, ")") : "var(--ds-background-input-hovered, ".concat(N30, ")");
      if (isDisabled) {
        backgroundColor = "var(--ds-background-disabled, ".concat(N20, ")");
        borderColor = "var(--ds-background-disabled, ".concat(N20, ")");
      }
      if (validationState === 'error') {
        borderColor = "var(--ds-border-danger, ".concat(R400, ")");
      }
      if (validationState === 'success') {
        borderColor = "var(--ds-border-success, ".concat(G400, ")");
      }
      var borderColorHover = isFocused ? "var(--ds-border-focused, ".concat(B100, ")") : "var(--ds-border-input, ".concat(N100, ")");
      if (validationState === 'error') {
        borderColorHover = "var(--ds-border-danger, ".concat(R400, ")");
      }
      if (validationState === 'success') {
        borderColorHover = "var(--ds-border-success, ".concat(G400, ")");
      }
      var transitionDuration = '200ms';
      if (appearance === 'subtle') {
        borderColor = isFocused ? "var(--ds-border-focused, ".concat(B100, ")") : 'transparent';
        backgroundColor = isFocused ? "var(--ds-surface, ".concat(N0, ")") : 'transparent';
        backgroundColorHover = isFocused ? "var(--ds-background-input-pressed, ".concat(N0, ")") : "var(--ds-background-input-hovered, ".concat(N30, ")");
      }
      if (appearance === 'none') {
        borderColor = 'transparent';
        backgroundColor = 'transparent';
        backgroundColorHover = 'transparent';
        borderColorHover = 'transparent';
      }
      return _objectSpread(_objectSpread({}, css), {}, {
        // Turn pointer events off when disabled - this makes it so hover etc don't work.
        pointerEvents: isDisabled ? 'none' : undefined,
        backgroundColor: backgroundColor,
        borderColor: borderColor,
        borderStyle: 'solid',
        borderRadius: "var(--ds-border-radius-100, 3px)",
        borderWidth: "var(--ds-border-width, 1px)",
        boxShadow: validationState === 'error' ? "inset 0 0 0 ".concat("var(--ds-border-width, 1px)", " ", borderColor) : 'none',
        '&:focus-within': {
          boxShadow: "inset 0 0 0 ".concat("var(--ds-border-width, 1px)", " ", borderColor)
        },
        minHeight: isCompact ? 32 : 40,
        padding: 0,
        transition: "background-color ".concat(transitionDuration, " ease-in-out,\n        border-color ").concat(transitionDuration, " ease-in-out"),
        '::-webkit-scrollbar': {
          height: 8,
          width: 8
        },
        '::-webkit-scrollbar-corner': {
          display: 'none'
        },
        ':hover': {
          '::-webkit-scrollbar-thumb': {
            // scrollbars occur only if the user passes in a custom component with overflow: scroll
            backgroundColor: 'rgba(0,0,0,0.2)'
          },
          cursor: 'pointer',
          backgroundColor: backgroundColorHover,
          borderColor: borderColorHover
        },
        '::-webkit-scrollbar-thumb:hover': {
          backgroundColor: 'rgba(0,0,0,0.4)'
        }
      });
    },
    valueContainer: function valueContainer(css) {
      return _objectSpread(_objectSpread({}, css), {}, {
        paddingLeft: "var(--ds-space-075, 6px)",
        paddingRight: "var(--ds-space-075, 6px)",
        paddingBottom: isCompact ? 0 : 2,
        paddingTop: isCompact ? 0 : 2
      });
    },
    clearIndicator: function clearIndicator(css) {
      return _objectSpread(_objectSpread({}, css), {}, {
        color: "var(--ds-text-subtlest, ".concat(N70, ")"),
        paddingLeft: "var(--ds-space-025, 2px)",
        paddingRight: "var(--ds-space-025, 2px)",
        paddingBottom: isCompact ? 0 : 6,
        paddingTop: isCompact ? 0 : 6,
        ':hover': {
          color: "var(--ds-text-subtle, ".concat(N500, ")")
        }
      });
    },
    loadingIndicator: function loadingIndicator(css) {
      return _objectSpread(_objectSpread({}, css), {}, {
        paddingBottom: isCompact ? 0 : 6,
        paddingTop: isCompact ? 0 : 6
      });
    },
    dropdownIndicator: function dropdownIndicator(css, _ref3) {
      var isDisabled = _ref3.isDisabled;
      var color = "var(--ds-text-subtle, ".concat(N500, ")");
      if (isDisabled) {
        color = "var(--ds-text-disabled, ".concat(N70, ")");
      }
      return _objectSpread(_objectSpread({}, css), {}, {
        color: color,
        paddingLeft: "var(--ds-space-025, 2px)",
        paddingRight: "var(--ds-space-025, 2px)",
        paddingBottom: isCompact ? 0 : 6,
        paddingTop: isCompact ? 0 : 6,
        ':hover': {
          color: "var(--ds-text-subtle, ".concat(N200, ")")
        }
      });
    },
    indicatorsContainer: function indicatorsContainer(css) {
      return _objectSpread(_objectSpread({}, css), {}, {
        paddingRight: "var(--ds-space-050, 4px)"
      });
    },
    option: function option(css, _ref4) {
      var isFocused = _ref4.isFocused,
        isSelected = _ref4.isSelected,
        isDisabled = _ref4.isDisabled;
      var color = "var(--ds-text, ".concat(N800, ")");
      if (isDisabled) {
        color = "var(--ds-text-disabled, ".concat(N70, ")");
      } else if (isSelected) {
        color = "var(--ds-text-selected, ".concat(B400, ")");
      }
      var boxShadow;
      var backgroundColor;
      if (isDisabled) {
        backgroundColor = undefined;
      } else if (isSelected && isFocused) {
        backgroundColor = "var(--ds-background-selected-hovered, ".concat(B50, ")");
      } else if (isSelected) {
        backgroundColor = "var(--ds-background-selected, ".concat(B50, ")");
      } else if (isFocused) {
        backgroundColor = "var(--ds-background-neutral-subtle-hovered, ".concat(N20, ")");
      }
      if (!isDisabled && (isFocused || isSelected)) {
        boxShadow = "inset 2px 0px 0px ".concat("var(--ds-border-selected, ".concat(B400, ")"));
      }
      var cursor = isDisabled ? 'not-allowed' : css.cursor;
      return _objectSpread(_objectSpread({}, css), {}, {
        padding: '6px 12px',
        backgroundColor: backgroundColor,
        color: color,
        cursor: cursor,
        boxShadow: boxShadow,
        ':active': {
          backgroundColor: !isDisabled ? isSelected ? "var(--ds-background-selected-pressed, ".concat(N20, ")") : "var(--ds-background-neutral-subtle-pressed, ".concat(N30, ")") : undefined
        },
        '@media screen and (-ms-high-contrast: active)': {
          borderLeft: !isDisabled && (isFocused || isSelected) ? '2px solid transparent' : ''
        }
      });
    },
    placeholder: function placeholder(css, _ref5) {
      var isDisabled = _ref5.isDisabled;
      return _objectSpread(_objectSpread({}, css), {}, {
        color: isDisabled ? "var(--ds-text-disabled, ".concat(N300, ")") : "var(--ds-text-subtlest, ".concat(N200, ")")
      });
    },
    singleValue: function singleValue(css, _ref6) {
      var isDisabled = _ref6.isDisabled;
      return _objectSpread(_objectSpread({}, css), {}, {
        color: isDisabled ? "var(--ds-text-disabled, ".concat(N70, ")") : "var(--ds-text, ".concat(N800, ")")
      });
    },
    menu: function menu(css) {
      return _objectSpread(_objectSpread({}, css), {}, {
        backgroundColor: "var(--ds-surface-overlay, white)",
        boxShadow: "var(--ds-shadow-overlay, 0 0 0 1px hsl(0deg 0% 0% / 10%), 0 4px 11px hsl(0deg 0% 0% / 10%))"
      });
    },
    menuList: function menuList(css) {
      return _objectSpread(_objectSpread({}, css), {}, {
        paddingTop: "var(--ds-space-100, 8px)",
        paddingBottom: "var(--ds-space-100, 8px)"
      });
    },
    multiValue: function multiValue(css, _ref7) {
      var isDisabled = _ref7.isDisabled,
        isFocused = _ref7.isFocused;
      var backgroundColor;
      var color;
      if (isDisabled) {
        // Use the basic neutral background so it is slightly separate from the
        // field's background
        backgroundColor = "var(--ds-background-neutral, ".concat(N40, ")");
        color = "var(--ds-text-disabled, ".concat(N70, ")");
      } else if (isFocused) {
        backgroundColor = "var(--ds-background-selected, ".concat(N40, ")");
        color = "var(--ds-text-selected, hsl(0, 0%, 20%))";
      } else {
        backgroundColor = fg('platform-component-visual-refresh') ? "var(--ds-background-neutral-subtle-hovered, #091E420F)" : "var(--ds-background-neutral, ".concat(N40, ")");
        color = "var(--ds-text, hsl(0, 0%, 20%))";
      }
      return _objectSpread(_objectSpread({}, css), {}, {
        borderRadius: "var(--ds-border-radius-050, 2px)",
        backgroundColor: backgroundColor,
        boxShadow: isFocused ? "0 0 0 2px ".concat("var(--ds-surface, transparent)", ", 0 0 0 4px ", "var(--ds-border-focused, transparent)") : 'none',
        maxWidth: '100%',
        '@media screen and (-ms-high-contrast: active)': {
          border: isFocused ? '1px solid transparent' : 'none'
        },
        color: color
      }, fg('platform-component-visual-refresh') && {
        borderRadius: "var(--ds-border-radius-100, 4px)",
        // Hardcode this color for visual refresh as there is no token color yet
        borderColor: '#B7B9BE',
        borderWidth: "var(--ds-border-width, 1px)",
        borderStyle: 'solid',
        backgroundColor: "var(--ds-background-input, #FFFFFF)"
      });
    },
    multiValueLabel: function multiValueLabel(css, _ref8) {
      var isDisabled = _ref8.isDisabled;
      return _objectSpread(_objectSpread({}, css), {}, {
        font: "var(--ds-font-body-UNSAFE_small, normal 400 12px/16px ui-sans-serif, -apple-system, BlinkMacSystemFont, \"Segoe UI\", Ubuntu, system-ui, \"Helvetica Neue\", sans-serif)",
        padding: "var(--ds-space-025, 2px)",
        color: isDisabled ? "var(--ds-text-disabled, ".concat(N70, ")") : 'inherit',
        paddingRight: "var(--ds-space-025, 2px)"
      }, fg('platform-component-visual-refresh') && {
        font: "var(--ds-font-body, normal 400 14px/20px ui-sans-serif, -apple-system, BlinkMacSystemFont, \"Segoe UI\", Ubuntu, system-ui, \"Helvetica Neue\", sans-serif)",
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: "var(--ds-space-050, 4px)"
      });
    },
    multiValueRemove: function multiValueRemove(css, _ref9) {
      var isFocused = _ref9.isFocused;
      return _objectSpread(_objectSpread({}, css), {}, {
        backgroundColor: isFocused ? "var(--ds-UNSAFE-transparent, ".concat(R75, ")") : undefined,
        fill: isFocused ? "var(--ds-text-selected, #000)" : "var(--ds-text, #000)",
        paddingLeft: "var(--ds-space-025, 2px)",
        paddingRight: "var(--ds-space-025, 2px)",
        borderRadius: '0px 2px 2px 0px',
        // DSP-6470 we should style like Tag once we have the :has selector
        ':hover': {
          backgroundColor: "var(--ds-background-danger-hovered, ".concat(R75, ")"),
          fill: "var(--ds-text-danger, #000)"
        },
        ':active': {
          backgroundColor: "var(--ds-background-danger-pressed, ".concat(R75, ")"),
          fill: "var(--ds-text-danger, #000)"
        }
      }, fg('platform-component-visual-refresh') && {
        backgroundColor: "var(--ds-background-neutral-subtle, #00000000)",
        border: 'none',
        alignItems: 'center',
        justifyContent: 'center',
        alignSelf: 'center',
        appearance: 'none',
        borderRadius: "var(--ds-border-radius, 4px)",
        color: "var(--ds-text, #172B4D)",
        padding: "var(--ds-space-025, 2px)",
        marginRight: "var(--ds-space-025, 2px)",
        ':focus-visible': {
          outlineOffset: "var(--ds-space-negative-025, -2px)"
        },
        ':hover': {
          backgroundColor: "var(--ds-background-neutral-subtle-hovered, #091E420F)"
        },
        ':active': {
          backgroundColor: "var(--ds-background-neutral-subtle-pressed, #091E4224)"
        }
      });
    },
    groupHeading: function groupHeading(css) {
      return _objectSpread(_objectSpread({}, css), {}, {
        font: "var(--ds-font-body-small, normal 400 11px/16px ui-sans-serif, -apple-system, BlinkMacSystemFont, \"Segoe UI\", Ubuntu, system-ui, \"Helvetica Neue\", sans-serif)",
        color: "var(--ds-text-subtle, ".concat(N200, ")"),
        fontWeight: "var(--ds-font-weight-bold, 700)".concat(" !important"),
        textTransform: 'none'
      });
    }
  };
}