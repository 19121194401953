import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
/* eslint-disable @atlaskit/design-system/ensure-design-token-usage */
import { N10, N30 } from '@atlaskit/theme/colors';
import { headingSizes } from '@atlaskit/theme/typography';
import { VAR_SCROLL_INDICATOR_COLOR, VAR_SEPARATOR_COLOR } from '../../common/constants';
var scrollIndicatorMaskZIndex = 2;
var scrollIndicatorZIndex = 1;
var scrollIndicatorHeight = 2;
var scrollIndicatorBorderRadius = '1px';
var containerPadding = 8;
var itemHeadingContentHeight = headingSizes.h100.lineHeight;
var skeletonHeadingHeight = containerPadding;
var skeletonHeadingMarginOffset = 3;
// Skeleton content is slightly shorter than the real content.
// Because of that we slightly increase the top margin to offset this so the
// containing size both real and skeleton always equal approx 30px.
var skeletonHeadingTopMargin = containerPadding * 2.5 + (itemHeadingContentHeight - skeletonHeadingHeight) - skeletonHeadingMarginOffset;
// We want to move the entire body up by 3px without affecting the height of the skeleton container.
var skeletonHeadingBottomMargin = containerPadding * 0.75 + skeletonHeadingMarginOffset;
/**
 * This outer container css contains the "real" scroll indicators which are
 * always rendered to the screen.
 * They are "conditionally" shown from the users perspective using the inner container CSS
 * which has other pseudo elements which "mask" the "real" scroll indicators.
 */
export var outerContainerCSS = function outerContainerCSS(opts) {
  return {
    // Flex is needed to ensure the overflow indicators are positioned correctly.
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    position: 'relative',
    '&::before': {
      content: "''",
      display: 'block',
      left: "var(--ds-space-100, 8px)",
      right: containerPadding + opts.scrollbarWidth,
      height: scrollIndicatorHeight,
      borderRadius: "var(--ds-border-radius-050, ".concat(scrollIndicatorBorderRadius, ")"),
      backgroundColor: "var(".concat(VAR_SEPARATOR_COLOR, ", ", "var(--ds-border, ".concat(N30, ")"), ")"),
      position: 'absolute',
      zIndex: scrollIndicatorZIndex
    },
    '&::after': {
      content: "''",
      position: 'absolute',
      display: 'block',
      borderRadius: "var(--ds-border-radius-050, ".concat(scrollIndicatorBorderRadius, ")"),
      flexShrink: 0,
      height: scrollIndicatorHeight,
      left: "var(--ds-space-100, 8px)",
      right: containerPadding + opts.scrollbarWidth,
      bottom: 0,
      zIndex: scrollIndicatorZIndex,
      backgroundColor: "var(".concat(VAR_SEPARATOR_COLOR, ", ", "var(--ds-border, ".concat(N30, ")"), ")")
    }
  };
};

/**
 * This inner container css contains the "mask" logic for the scroll indicators.
 * Essentially they cover (mask) the "real" scroll indicators when the user is scrolled
 * to the top or bottom of the container.
 */
export var innerContainerCSS = function innerContainerCSS(opts) {
  return _objectSpread(_objectSpread({
    display: 'flex',
    overflow: 'auto',
    width: '100%',
    position: 'relative',
    boxSizing: 'border-box',
    flexDirection: 'column'
  }, !opts.showTopScrollIndicator && {
    '&::before': {
      borderRadius: "var(--ds-border-radius-050, ".concat(scrollIndicatorBorderRadius, ")"),
      content: "''",
      left: 0,
      right: 0,
      height: scrollIndicatorHeight,
      backgroundColor: "var(".concat(VAR_SCROLL_INDICATOR_COLOR, ", ", "var(--ds-surface, ".concat(N10, ")"), ")"),
      position: 'absolute',
      display: 'block',
      zIndex: scrollIndicatorMaskZIndex
    }
  }), {}, {
    // This after pseudo element abuses being a flex child and pushes itself down to the
    // very bottom of the container - doing so ends up "masking" the actual scroll indicator.
    '&::after': {
      borderRadius: "var(--ds-border-radius-050, ".concat(scrollIndicatorBorderRadius, ")"),
      content: "''",
      display: 'block',
      flexShrink: 0,
      height: scrollIndicatorHeight,
      // This is used to "push" the element to the bottom of the flex container.
      marginTop: 'auto',
      position: 'relative',
      zIndex: scrollIndicatorMaskZIndex,
      backgroundColor: "var(".concat(VAR_SCROLL_INDICATOR_COLOR, ", ", "var(--ds-surface, ".concat(N10, ")"), ")")
    }
  });
};
export var containerCSS = function containerCSS(opts) {
  return {
    // When the scroll indicator is always shown we need to add some padding
    // so the spacing between matches what it would be if the indicator was a "block" element.
    // We use margin here so any child absolutely positioned elements are positioned correctly.
    marginTop: opts.showTopScrollIndicator ? scrollIndicatorHeight : 0,
    marginLeft: "var(--ds-space-100, 8px)",
    marginRight: "var(--ds-space-100, 8px)",
    // Enables child absolutely positioned elements to be positioned relative to this element.
    position: 'relative',
    '& [data-ds--menu--heading-item]': {
      marginBottom: "var(--ds-space-075, 6px)",
      marginTop: "var(--ds-space-200, 16px)"
    },
    '& [data-ds--menu--skeleton-heading-item]': {
      marginTop: skeletonHeadingTopMargin,
      marginBottom: skeletonHeadingBottomMargin
    }
  };
};