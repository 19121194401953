import _extends from "@babel/runtime/helpers/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
var _excluded = ["innerRef"];
/**
 * @jsxRuntime classic
 * @jsx jsx
 */

import { css, jsx } from '@emotion/react';
import { removeProps } from '../../utils';
var dummyInputStyles = css({
  width: 1,
  padding: 0,
  position: 'relative',
  background: 0,
  border: 0,
  caretColor: 'transparent',
  color: 'transparent',
  fontSize: 'inherit',
  gridArea: '1 / 1 / 2 / 3',
  insetInlineStart: -100,
  label: 'dummyInput',
  opacity: 0,
  outline: 0,
  transform: 'scale(.01)'
});
export default function DummyInput(_ref) {
  var innerRef = _ref.innerRef,
    props = _objectWithoutProperties(_ref, _excluded);
  // Remove animation props not meant for HTML elements
  var filteredProps = removeProps(props, 'onExited', 'in', 'enter', 'exit', 'appear');
  return jsx("input", _extends({
    ref: innerRef
  }, filteredProps, {
    css: dummyInputStyles
  }));
}