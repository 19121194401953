import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { eventType } from './analyticsWebTypes';
import { isPlainObject } from './util/object-utils';
const libraryVersion = "4.26.0";
function buildTenantFields(tenantInfo) {
  return {
    tenantIdType: tenantInfo.tenantIdType,
    tenantId: tenantInfo.tenantId
  };
}
function buildUserFields(userInfo) {
  return {
    userIdType: userInfo.userIdType
  };
}
function buildOrgFields(orgInfo) {
  return {
    orgId: orgInfo.orgId
  };
}
function buildWorkspaceFields(workspaceInfo) {
  return {
    workspaceId: workspaceInfo.workspaceId
  };
}
function buildAliases(aliases) {
  if (isNotEmptyObject(aliases)) {
    return {
      aliases: aliases
    };
  }
  return undefined;
}
function buildGroups(groups) {
  if (isNotEmptyObject(groups)) {
    return {
      groups: groups
    };
  }
  return undefined;
}
function buildEventOverrides(event) {
  if (event.anonymousId) {
    return {
      anonymousId: event.anonymousId
    };
  }
  return {};
}
function getProductField(field, override) {
  return override === undefined ? field : override;
}
function buildProductFields(productInfo, productOverrides) {
  const overrides = productOverrides || {};
  const env = getProductField(productInfo.env, overrides.env);
  const product = getProductField(productInfo.product, overrides.product);
  const subproduct = getProductField(productInfo.subproduct(), overrides.subproduct);
  const version = getProductField(productInfo.version, overrides.version);
  const origin = getProductField(productInfo.origin, overrides.origin);
  const platform = getProductField(productInfo.platform, overrides.platform);
  return {
    env,
    product,
    subproduct,
    version,
    origin,
    platform
  };
}
function isNotEmptyObject(obj) {
  return isPlainObject(obj) && Object.keys(obj).length > 0;
}
function buildAttributesWithName(name, attrObject) {
  return isNotEmptyObject(attrObject) ? {
    [name]: attrObject
  } : undefined;
}
function buildAttributes(attributes) {
  return buildAttributesWithName('attributes', attributes);
}
export function filterAndBuildContainers(containers) {
  const validContainers = {};
  Object.keys(containers).forEach(key => {
    const value = containers[key];
    validContainers[key] = {
      id: value.id,
      type: value.type
    };
  });
  return validContainers;
}
export function buildContainersWithName(containers) {
  if (isNotEmptyObject(containers)) {
    const validContainers = filterAndBuildContainers(containers);
    return buildAttributesWithName('containers', validContainers);
  }
  return undefined;
}
export function buildActionFields(event, actionEventType) {
  return _objectSpread({
    containerType: event.containerType,
    containerId: event.containerId,
    source: event.source,
    objectType: event.objectType,
    objectId: event.objectId,
    actionSubject: event.actionSubject,
    action: event.action,
    actionSubjectId: event.actionSubjectId,
    attributes: event.attributes,
    nonPrivacySafeAttributes: event.nonPrivacySafeAttributes,
    tags: event.tags,
    highPriority: event.highPriority,
    eventType: actionEventType
  }, buildContainersWithName(event.containers));
}
function buildNonPrivacySafeAttributes(nonPrivacySafeAttributes) {
  return buildAttributesWithName('nonPrivacySafeAttributes', nonPrivacySafeAttributes);
}
export function buildScreenEvent(productInfo, tenantInfo, userInfo, attributes, nonPrivacySafeAttributes, tags, tabId, sessionId, taskSessions, orgInfo, pageLoadId, workspaceInfo, event, containers, aliases, groups) {
  const productFields = buildProductFields(productInfo);
  const tenantFields = buildTenantFields(tenantInfo);
  const userFields = buildUserFields(userInfo);
  const orgFields = buildOrgFields(orgInfo);
  const workspaceFields = buildWorkspaceFields(workspaceInfo);
  const overrides = {
    title: '',
    path: '',
    url: '',
    referrer: '',
    search: '',
    eventType: eventType.SCREEN
  };
  return _objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread({}, productFields), tenantFields), userFields), orgFields), overrides), buildAttributes(attributes)), buildNonPrivacySafeAttributes(nonPrivacySafeAttributes)), buildContainersWithName(containers)), buildEventOverrides(event)), {}, {
    tags,
    tabId,
    sessionId,
    taskSessions,
    pageLoadId
  }, workspaceFields), buildAliases(aliases)), buildGroups(groups));
}
export function buildActionName(event) {
  return `${event.actionSubject} ${event.action}`;
}
export function buildContext(productInfo) {
  const screen = window.screen || {};
  return {
    context: {
      locale: productInfo.locale,
      screen: {
        width: screen.width,
        height: screen.height,
        density: window.devicePixelRatio
      },
      library: {
        name: 'analytics.js',
        version: libraryVersion
      }
    }
  };
}
export function extractProductOverrides(event) {
  const {
    env,
    product,
    subproduct,
    version,
    origin,
    platform
  } = event;
  return {
    env,
    product,
    subproduct,
    version,
    origin,
    platform
  };
}
export function buildActionEvent(productInfo, tenantInfo, userInfo, event, actionEventType, tabId, sessionId, taskSessions, orgInfo, pageLoadId, workspaceInfo, aliases, groups) {
  const productFields = buildProductFields(productInfo, extractProductOverrides(event));
  const tenantFields = buildTenantFields(tenantInfo);
  const orgFields = buildOrgFields(orgInfo);
  const workspaceFields = buildWorkspaceFields(workspaceInfo);
  const userFields = buildUserFields(userInfo);
  const actionFields = buildActionFields(event, actionEventType);
  return _objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread({}, productFields), tenantFields), orgFields), userFields), actionFields), buildEventOverrides(event)), {}, {
    tabId,
    sessionId,
    taskSessions,
    pageLoadId
  }, workspaceFields), buildAliases(aliases)), buildGroups(groups));
}