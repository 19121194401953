import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _extends from "@babel/runtime/helpers/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
var _excluded = ["size"],
  _excluded2 = ["innerProps", "isRtl", "size"];
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
/**
 * @jsxRuntime classic
 * @jsx jsx
 */

import { css, jsx, keyframes } from '@emotion/react';
import { getStyleProps } from '../utils';

// ==============================
// Dropdown & Clear Icons
// ==============================

var styles = css({
  display: 'inline-block',
  fill: 'currentColor',
  lineHeight: 1,
  stroke: 'currentColor',
  strokeWidth: 0
});
var Svg = function Svg(_ref) {
  var size = _ref.size,
    props = _objectWithoutProperties(_ref, _excluded);
  return jsx("svg", _extends({
    height: size,
    width: size,
    viewBox: "0 0 20 20",
    "aria-hidden": "true",
    focusable: "false",
    css: styles
    // eslint-disable-next-line @repo/internal/react/no-unsafe-spread-props
  }, props));
};
// eslint-disable-next-line @repo/internal/react/require-jsdoc
export var CrossIcon = function CrossIcon(props) {
  return (
    // eslint-disable-next-line @repo/internal/react/no-unsafe-spread-props
    jsx(Svg, _extends({
      size: 20
    }, props), jsx("path", {
      d: "M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"
    }))
  );
};
// eslint-disable-next-line @repo/internal/react/require-jsdoc
export var DownChevron = function DownChevron(props) {
  return (
    // eslint-disable-next-line @repo/internal/react/no-unsafe-spread-props
    jsx(Svg, _extends({
      size: 20
    }, props), jsx("path", {
      d: "M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"
    }))
  );
};

// ==============================
// Dropdown & Clear Buttons
// ==============================

var baseCSS = function baseCSS(_ref2, unstyled) {
  var isFocused = _ref2.isFocused,
    _ref2$theme = _ref2.theme,
    baseUnit = _ref2$theme.spacing.baseUnit,
    colors = _ref2$theme.colors;
  return _objectSpread({
    label: 'indicatorContainer',
    display: 'flex',
    transition: 'color 150ms'
  }, unstyled ? {} : {
    color: isFocused ? colors.neutral60 : colors.neutral20,
    padding: baseUnit * 2,
    ':hover': {
      color: isFocused ? colors.neutral80 : colors.neutral40
    }
  });
};
export var dropdownIndicatorCSS = baseCSS;

// eslint-disable-next-line @repo/internal/react/require-jsdoc
export var DropdownIndicator = function DropdownIndicator(props) {
  var children = props.children,
    innerProps = props.innerProps;
  return jsx("div", _extends({}, getStyleProps(props, 'dropdownIndicator', {
    indicator: true,
    'dropdown-indicator': true
  }), innerProps), children || jsx(DownChevron, null));
};
export var clearIndicatorCSS = baseCSS;

// eslint-disable-next-line @repo/internal/react/require-jsdoc
export var ClearIndicator = function ClearIndicator(props) {
  var children = props.children,
    innerProps = props.innerProps;
  return jsx("div", _extends({}, getStyleProps(props, 'clearIndicator', {
    indicator: true,
    'clear-indicator': true
  }), innerProps), children || jsx(CrossIcon, null));
};

// ==============================
// Separator
// ==============================

export var indicatorSeparatorCSS = function indicatorSeparatorCSS(_ref3, unstyled) {
  var isDisabled = _ref3.isDisabled,
    _ref3$theme = _ref3.theme,
    baseUnit = _ref3$theme.spacing.baseUnit,
    colors = _ref3$theme.colors;
  return _objectSpread({
    label: 'indicatorSeparator',
    alignSelf: 'stretch',
    width: 1
  }, unstyled ? {} : {
    backgroundColor: isDisabled ? colors.neutral10 : colors.neutral20,
    marginBottom: baseUnit * 2,
    marginTop: baseUnit * 2
  });
};

// eslint-disable-next-line @repo/internal/react/require-jsdoc
export var IndicatorSeparator = function IndicatorSeparator(props) {
  var innerProps = props.innerProps;
  return jsx("span", _extends({}, innerProps, getStyleProps(props, 'indicatorSeparator', {
    'indicator-separator': true
  })));
};

// ==============================
// Loading
// ==============================

var loadingDotAnimations = keyframes({
  '0%, 80%, 100%': {
    opacity: 0
  },
  '40%': {
    opacity: 1
  }
});
export var loadingIndicatorCSS = function loadingIndicatorCSS(_ref4, unstyled) {
  var isFocused = _ref4.isFocused,
    size = _ref4.size,
    _ref4$theme = _ref4.theme,
    colors = _ref4$theme.colors,
    baseUnit = _ref4$theme.spacing.baseUnit;
  return _objectSpread({
    label: 'loadingIndicator',
    display: 'flex',
    transition: 'color 150ms',
    alignSelf: 'center',
    fontSize: size,
    lineHeight: 1,
    marginRight: size,
    textAlign: 'center',
    verticalAlign: 'middle'
  }, unstyled ? {} : {
    color: isFocused ? colors.neutral60 : colors.neutral20,
    padding: baseUnit * 2
  });
};
var LoadingDot = function LoadingDot(_ref5) {
  var delay = _ref5.delay,
    offset = _ref5.offset;
  return jsx("span", {
    // eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage
    css: {
      animation: "".concat(loadingDotAnimations, " 1s ease-in-out ").concat(delay, "ms infinite;"),
      backgroundColor: 'currentColor',
      borderRadius: '1em',
      display: 'inline-block',
      marginLeft: offset ? '1em' : undefined,
      height: '1em',
      verticalAlign: 'top',
      width: '1em'
    }
  });
};
// eslint-disable-next-line @repo/internal/react/require-jsdoc
export var LoadingIndicator = function LoadingIndicator(_ref6) {
  var innerProps = _ref6.innerProps,
    isRtl = _ref6.isRtl,
    _ref6$size = _ref6.size,
    size = _ref6$size === void 0 ? 4 : _ref6$size,
    restProps = _objectWithoutProperties(_ref6, _excluded2);
  return jsx("div", _extends({}, getStyleProps(_objectSpread(_objectSpread({}, restProps), {}, {
    innerProps: innerProps,
    isRtl: isRtl,
    size: size
  }), 'loadingIndicator', {
    indicator: true,
    'loading-indicator': true
  }), innerProps), jsx(LoadingDot, {
    delay: 0,
    offset: isRtl
  }), jsx(LoadingDot, {
    delay: 160,
    offset: true
  }), jsx(LoadingDot, {
    delay: 320,
    offset: !isRtl
  }));
};