import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { N200, N800 } from './colors';
import { gridSize } from './constants';
/**
 * Font token fallbacks are verbose.
 * This object exists purely to make using fallbacks less cumbersome while we still need to use them.
 * Will be removed once fallbacks are no longer needed.
 *
 * This is referenced by the use-tokens-typography ESLint rule.
 */
export var fontFallback = {
  heading: {
    xxlarge: 'normal 500 35px/40px ui-sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Ubuntu, system-ui, "Helvetica Neue", sans-serif',
    xlarge: 'normal 600 29px/32px ui-sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Ubuntu, system-ui, "Helvetica Neue", sans-serif',
    large: 'normal 500 24px/28px ui-sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Ubuntu, system-ui, "Helvetica Neue", sans-serif',
    medium: 'normal 500 20px/24px ui-sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Ubuntu, system-ui, "Helvetica Neue", sans-serif',
    small: 'normal 600 16px/20px ui-sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Ubuntu, system-ui, "Helvetica Neue", sans-serif',
    xsmall: 'normal 600 14px/16px ui-sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Ubuntu, system-ui, "Helvetica Neue", sans-serif',
    xxsmall: 'normal 600 12px/16px ui-sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Ubuntu, system-ui, "Helvetica Neue", sans-serif'
  },
  body: {
    large: 'normal 400 16px/24px ui-sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Ubuntu, system-ui, "Helvetica Neue", sans-serif',
    medium: 'normal 400 14px/20px ui-sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Ubuntu, system-ui, "Helvetica Neue", sans-serif',
    UNSAFE_small: 'normal 400 12px/16px ui-sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Ubuntu, system-ui, "Helvetica Neue", sans-serif',
    small: 'normal 400 11px/16px ui-sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Ubuntu, system-ui, "Helvetica Neue", sans-serif'
  }
};
var baseHeading = function baseHeading(size, lineHeight) {
  return {
    fontSize: "".concat(size / 14, "em"),
    fontStyle: 'inherit',
    lineHeight: lineHeight / size
  };
};
export var headingSizes = {
  h900: {
    size: 35,
    lineHeight: 40
  },
  h800: {
    size: 29,
    lineHeight: 32
  },
  h700: {
    size: 24,
    lineHeight: 28
  },
  h600: {
    size: 20,
    lineHeight: 24
  },
  h500: {
    size: 16,
    lineHeight: 20
  },
  h400: {
    size: 14,
    lineHeight: 16
  },
  h300: {
    size: 12,
    lineHeight: 16
  },
  h200: {
    size: 12,
    lineHeight: 16
  },
  h100: {
    size: 11,
    lineHeight: 16
  }
};

/**
 * @deprecated {@link https://hello.atlassian.net/browse/ENGHEALTH-386 Internal documentation for deprecation (no external access)}
 * @deprecated {@link https://atlassian.design/components/heading Use `@atlaskit/heading` instead.}
 */
export var h900 = function h900() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _objectSpread(_objectSpread({}, baseHeading(headingSizes.h900.size, headingSizes.h900.lineHeight)), {}, {
    color: "var(--ds-text, ".concat(N800, ")"),
    fontWeight: "var(--ds-font-weight-medium, 500)",
    letterSpacing: "-0.01em",
    marginTop: "".concat(gridSize() * 6.5, "px")
  });
};

/**
 * @deprecated {@link https://hello.atlassian.net/browse/ENGHEALTH-386 Internal documentation for deprecation (no external access)}
 * @deprecated {@link https://atlassian.design/components/heading Use `@atlaskit/heading` instead.}
 */
export var h800 = function h800() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _objectSpread(_objectSpread({}, baseHeading(headingSizes.h800.size, headingSizes.h800.lineHeight)), {}, {
    color: "var(--ds-text, ".concat(N800, ")"),
    fontWeight: "var(--ds-font-weight-semibold, 600)",
    letterSpacing: "-0.01em",
    marginTop: "".concat(gridSize() * 5, "px")
  });
};

/**
 * @deprecated {@link https://hello.atlassian.net/browse/ENGHEALTH-386 Internal documentation for deprecation (no external access)}
 * @deprecated {@link https://atlassian.design/components/heading Use `@atlaskit/heading` instead.}
 */
export var h700 = function h700() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _objectSpread(_objectSpread({}, baseHeading(headingSizes.h700.size, headingSizes.h700.lineHeight)), {}, {
    color: "var(--ds-text, ".concat(N800, ")"),
    fontWeight: "var(--ds-font-weight-medium, 500)",
    letterSpacing: "-0.01em",
    marginTop: "".concat(gridSize() * 5, "px")
  });
};

/**
 * @deprecated {@link https://hello.atlassian.net/browse/ENGHEALTH-386 Internal documentation for deprecation (no external access)}
 * @deprecated {@link https://atlassian.design/components/heading Use `@atlaskit/heading` instead.}
 */
export var h600 = function h600() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _objectSpread(_objectSpread({}, baseHeading(headingSizes.h600.size, headingSizes.h600.lineHeight)), {}, {
    color: "var(--ds-text, ".concat(N800, ")"),
    fontWeight: "var(--ds-font-weight-medium, 500)",
    letterSpacing: "-0.008em",
    marginTop: "".concat(gridSize() * 3.5, "px")
  });
};

/**
 * @deprecated {@link https://hello.atlassian.net/browse/ENGHEALTH-386 Internal documentation for deprecation (no external access)}
 * @deprecated {@link https://atlassian.design/components/heading Use `@atlaskit/heading` instead.}
 */
export var h500 = function h500() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _objectSpread(_objectSpread({}, baseHeading(headingSizes.h500.size, headingSizes.h500.lineHeight)), {}, {
    color: "var(--ds-text, ".concat(N800, ")"),
    fontWeight: "var(--ds-font-weight-semibold, 600)",
    letterSpacing: "-0.006em",
    marginTop: "".concat(gridSize() * 3, "px")
  });
};

/**
 * @deprecated {@link https://hello.atlassian.net/browse/ENGHEALTH-386 Internal documentation for deprecation (no external access)}
 * @deprecated {@link https://atlassian.design/components/heading Use `@atlaskit/heading` instead.}
 */
export var h400 = function h400() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _objectSpread(_objectSpread({}, baseHeading(headingSizes.h400.size, headingSizes.h400.lineHeight)), {}, {
    color: "var(--ds-text, ".concat(N800, ")"),
    fontWeight: "var(--ds-font-weight-semibold, 600)",
    letterSpacing: "-0.003em",
    marginTop: "".concat(gridSize() * 2, "px")
  });
};

/**
 * @deprecated {@link https://hello.atlassian.net/browse/ENGHEALTH-386 Internal documentation for deprecation (no external access)}
 * @deprecated {@link https://atlassian.design/components/heading Use `@atlaskit/heading` instead.}
 */
export var h300 = function h300() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _objectSpread(_objectSpread({}, baseHeading(headingSizes.h300.size, headingSizes.h300.lineHeight)), {}, {
    color: "var(--ds-text, ".concat(N800, ")"),
    fontWeight: "var(--ds-font-weight-semibold, 600)",
    marginTop: "".concat(gridSize() * 2.5, "px"),
    textTransform: 'uppercase'
  });
};

/**
 * @deprecated {@link https://hello.atlassian.net/browse/ENGHEALTH-386 Internal documentation for deprecation (no external access)}
 * @deprecated {@link https://atlassian.design/components/heading Use `@atlaskit/heading` instead.}
 */
export var h200 = function h200() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _objectSpread(_objectSpread({}, baseHeading(headingSizes.h200.size, headingSizes.h200.lineHeight)), {}, {
    color: "var(--ds-text-subtlest, ".concat(N200, ")"),
    fontWeight: "var(--ds-font-weight-semibold, 600)",
    marginTop: "".concat(gridSize() * 2, "px")
  });
};

/**
 * @deprecated {@link https://hello.atlassian.net/browse/ENGHEALTH-386 Internal documentation for deprecation (no external access)}
 * @deprecated {@link https://atlassian.design/components/heading Use `@atlaskit/heading` instead.}
 */
export var h100 = function h100() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _objectSpread(_objectSpread({}, baseHeading(headingSizes.h100.size, headingSizes.h100.lineHeight)), {}, {
    color: "var(--ds-text-subtlest, ".concat(N200, ")"),
    fontWeight: "var(--ds-font-weight-bold, 700)",
    marginTop: "".concat(gridSize() * 2, "px")
  });
};