/**
 * @jsxRuntime classic
 * @jsx jsx
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, jsx } from '@emotion/react';
import LegacySelectClearIcon from '@atlaskit/icon/glyph/select-clear';
import CrossIcon from '@atlaskit/icon/utility/cross';
import { fg } from '@atlaskit/platform-feature-flags';
import { Inline, xcss } from '@atlaskit/primitives';
import { components } from '@atlaskit/react-select';
export { ClearIndicator, DropdownIndicator, LoadingIndicator } from './indicators';
var disabledStyles = css({
  display: 'none'
});
var enabledStyles = css({
  display: 'inherit'
});
var iconWrapperStyles = xcss({
  padding: 'space.025'
});

/**
 * __Multi value remove__
 *
 * The icon used to remove individual selections from a multi-select.
 *
 */
export var MultiValueRemove = function MultiValueRemove(props) {
  var isDisabled = props.selectProps.isDisabled;
  var renderIcon = function renderIcon() {
    // eslint-disable-next-line @atlaskit/platform/ensure-feature-flag-prefix
    if (fg('platform-component-visual-refresh')) {
      return jsx(CrossIcon, {
        label: "Clear",
        color: "currentColor"
      });
    }

    // eslint-disable-next-line @atlaskit/platform/ensure-feature-flag-prefix
    if (fg('platform-visual-refresh-icons-legacy-facade')) {
      return jsx(Inline, {
        xcss: iconWrapperStyles
      }, jsx(CrossIcon, {
        label: "Clear",
        color: "currentColor"
      }));
    }
    return (
      // eslint-disable-next-line @atlaskit/design-system/no-legacy-icons
      jsx(LegacySelectClearIcon, {
        label: "Clear",
        primaryColor: "transparent",
        size: "small",
        secondaryColor: "inherit"
      })
    );
  };
  return (
    // eslint-disable-next-line @repo/internal/react/no-unsafe-spread-props
    jsx(components.MultiValueRemove, props, jsx("div", {
      css: isDisabled ? disabledStyles : enabledStyles,
      "data-testid": isDisabled ? 'hide-clear-icon' : 'show-clear-icon'
    }, renderIcon()))
  );
};

// eslint-disable-next-line @repo/internal/react/require-jsdoc
export var IndicatorSeparator = null;